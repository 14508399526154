body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nav_leftbar_section{
  background-color: #10163A;
  padding: 20px 0px;
  width: 78px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.nav_leftbar_section .nav_link{
  margin-top: 20px;
}
.nav_leftbar_section .nav_link img{
  margin: 0px auto;
}
.chat_screen_bg{
  background-color: #F8F8F8;
  padding: 20px;
  height: 100vh;
  width: 100%;
}
/*.chat_screen_bg .left_section .chat_header_section{
  border: 1px solid #DCDEDF;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}*/
.chat_screen_bg .left_section .chat_body_section .tabs_section .tabs_head_section .chakra-tabs__tablist{
  border-bottom: 0px;
}

.chat_screen_bg .left_section .chat_header_section .username_text{
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.chat_screen_bg .left_section .chat_header_section .user_box{
  width: 34px;
  height: 33px;
  background-color: #FF9F43;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.chat_screen_bg .left_section .chat_header_section .user_box p{
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.chat_screen_bg .left_section .chat_header_section .user_box .green_box{
  background-color: #28C76F;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.chat_screen_bg .left_section .chat_body_section{
      background-color: #fff;
    border: 1px solid #dcdedf;
    border-radius: 5px;
    height: calc(100vh - 40px);

}
.chat_screen_bg .left_section .chat_body_section .tabs_section .tabs_head_section{
  border-bottom: 1px solid #DCDEDF;
  padding: 7px 20px;
}
.chat_screen_bg .left_section .chat_body_section .tabs_section .tabs_head_section button{
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-right: 20px;
}
.chat_screen_bg .left_section .chat_body_section .tabs_section .tabs_head_section button:last-child{
  margin-right: 0px;
}
.chat_screen_bg .left_section .chat_body_section .tabs_section .tabs_head_section button[aria-selected="true"]{
  background-color: #363A77;
  color: #fff;
  border-radius: 5px;
}
.chat_screen_bg .left_section .chat_body_section .tabs_section .tabs_head_section button .badge_box{
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background-color: #EA5455;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}
.chat_screen_bg .left_section .chat_body_section .all_chats_section{
   border-right: 1px solid #dcdedf;
    height: calc(100vh - 98px);
    width: 25%;

}
.chat_screen_bg .left_section .chat_body_section .chats_section{
  gap: 0;
  align-items: flex-start;
}
.chat_screen_bg .left_section .chat_body_section .all_chats_section .head_section{
  display: flex;
  padding: 10px 20px;
  align-items: center;
  border-bottom: 1px solid #DCDEDF;
}
.chat_screen_bg .left_section .chat_body_section .chats_section .search_section{
  display: flex;
  border: 1px solid #DCDEDF;
  border-radius: 5px;
  align-items: center;
  padding: 6px 10px;
  margin-left: 10px;
  width: calc(100% - 33px);
}
.chat_screen_bg .left_section .chat_body_section .all_chats_section .dropdown_section{
  padding: 10px 20px;
  border-bottom: 1px solid #DCDEDF;
}
.chat_screen_bg .chat_body_section .all_chats_section .chat_user_section{
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DCDEDF;
}
.chat_screen_bg .chat_body_section .all_chats_section .chat_user_section .user_box .whatsapp_img{
  position: absolute;
  right: -5px;
  bottom: -5px;
}
.chat_screen_bg .chat_body_section .all_chats_section .chat_user_section .username_text{
  font-size: 14px;
  font-weight: bold;
  color: #10163A;
  line-height: 16px;
}
.chat_screen_bg .chat_body_section .all_chats_section .chat_user_section .number_text{
  font-size: 14px;
  font-weight: 400;
  color: #10163A;
  line-height: 16px;
}
.chat_screen_bg .chat_body_section .all_chats_section .chat_user_section .status_text{
  font-size: 10px;
  font-weight: bold;
  color: #001FFF;
  line-height: 12px;
  text-align: right;
}
.chat_screen_bg .chat_body_section .all_chats_section .chat_user_section .time_text{
  font-size: 10px;
  font-weight: 400;
  color: #000;
  line-height: 12px;
  text-align: right;
}
.chat_screen_bg .chat_body_section .all_chats_section .chat_user_section .badge_section{
  background-color: #28C76F;
  font-size: 10px;
  color: #fff;
  font-weight: 400;
  padding: 2px 20px;
  text-transform: capitalize;
  border-radius: 10px;
}
.bg_purple{
  background-color: #5E50EE !important;
}
.bg_green{
  background-color: #28C76F !important;
}
.bg_lightblue{
  background-color: #4FA9F7 !important;
}

.chat_screen_bg .left_section .chat_body_section .all_chats_section .dropdown_section .head_text{
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-top: 10px;
  margin-bottom: 15px;
}
.chat_screen_bg .left_section .chat_body_section .chats_section .search_section input{
  border: 0px;
  padding: 0px;
  height: unset;
  margin-left: 5px;
}
.chat_screen_bg .left_section .chat_body_section .chats_section .user_box{
  width: 34px;
  height: 33px;
  background-color: #00CFE8;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.chat_screen_bg .left_section .chat_body_section .chats_section .user_box p{
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.chat_screen_bg .left_section .chat_body_section .chats_section .user_box .green_box{
  background-color: #28C76F;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.chat_screen_bg .left_section .chat_body_section .chating_section{
  width: 75%;
}
.chat_screen_bg .left_section .chat_body_section .chating_section .head_section{
  border-bottom: 1px solid #DCDEDF;
  padding: 17px 20px;
}
.chat_screen_bg .left_section .chat_body_section .chating_section .head_section p{
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.chat_screen_bg .left_section .chat_body_section .chating_section .all_message_section{
   background-color: #f3f9fd;
    height: calc(100vh - 218px);
    overflow-y: scroll;
    padding: 15px;

}
.chat_screen_bg .left_section .chating_section .all_message_section .user_message_section{
  display: flex;
  margin-bottom: 15px;
}
.chat_screen_bg .left_section .chating_section .all_message_section .user_message_section .user_box{
  margin-right: 15px;
}
.chat_screen_bg .left_section .chating_section .all_message_section .user_message_section .whatsapp_img{
  position: absolute;
  right: -5px;
  bottom: -5px;
}
.chat_screen_bg .left_section .chating_section .all_message_section .user_message_section .username_text{
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #10163A;
  margin-bottom: 5px;
}
.chat_screen_bg .left_section .chating_section .all_message_section .user_message_section .message_box{
  background-color: #fff;
  padding: 7px 12px;
  border-radius: 5px;
  width: 220px;
}
.chat_screen_bg .left_section .chating_section .all_message_section .user_message_section .message_text{
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #10163A;
}
.chat_screen_bg .left_section .chating_section .all_message_section .user_message_section .time_text{
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #10163A;
  text-align: right;
}
.chat_screen_bg .left_section .chating_section .all_message_section .bot_message_section{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.chat_screen_bg .left_section .chating_section .all_message_section .bot_message_section .img_box{
  margin-left: 15px;
}
.chat_screen_bg .left_section .chating_section .all_message_section .bot_message_section .whatsapp_img{
  position: absolute;
  right: -5px;
  bottom: -5px;
}
.chat_screen_bg .left_section .chating_section .all_message_section .bot_message_section .username_text{
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #10163A;
  margin-bottom: 5px;
  text-align: right;
}
.chat_screen_bg .left_section .chating_section .all_message_section .bot_message_section .message_box{
  background-color: #E5EBEA;
  padding: 7px 12px;
  border-radius: 5px;
  width: 400px;
}
.chat_screen_bg .left_section .chating_section .all_message_section .bot_message_section .message_text{
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #10163A;
}
.chat_screen_bg .left_section .chating_section .all_message_section .bot_message_section .time_text{
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #10163A;
  text-align: right;
}
.chat_screen_bg .left_section .chating_section .message_field_section{
  display: flex;
  align-items: center;
  background-color: #F8F8F8;
  padding: 10px 20px;
  border-top: 1px solid #DCDEDF;
  border-bottom: 1px solid #DCDEDF;
}
.chat_screen_bg .left_section .chating_section .message_field_section .message_field{
  border: 0;
  padding: 0px;
}
.chat_screen_bg .left_section .chating_section .message_field_section .send_btn{
  background-color: #363A77;
  height: unset;
  width: 38px;
  height: 38px;
  padding: 0;
  border-radius: 50px;
  margin-left: 20px;
}
.detail_leftbar_section{
  border: 1px solid #DCDEDF;
  border-radius: 5px;
  background-color: #fff;
  height: calc(100vh - 40px);
}
.detail_leftbar_section .detail_name_section{
  padding: 15px;
}
.detail_leftbar_section .detail_name_section .profile_box_section{
  background-color: #28C76F;
  border-radius: 50px;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 5px;
}
.detail_leftbar_section .detail_name_section .profile_box_section .icon_img{
  position: absolute;
  bottom: -2px;
  right: -2px;
}
.detail_leftbar_section .detail_name_section .profile_box_section p{
  font-size: 33px;
  color: #fff;
  font-weight: 800;
}
.detail_leftbar_section .detail_name_section .text_section h2{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #000;
}
.detail_leftbar_section .detail_name_section .text_section p{
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: #000;
}
.detail_leftbar_section .tabs_section{
  border-bottom: 1px solid #DCDEDF;
}
.detail_leftbar_section .tabs_section .tabs_head_section{
  border-top: 1px solid #DCDEDF;
  border-bottom: 1px solid #DCDEDF;
  padding: 5px 10px;
}
.detail_leftbar_section .tabs_section .tabs_head_section button{
  width: 50%;
}
.detail_leftbar_section .tabs_section .tabs_head_section button[aria-selected="true"]{
  background-color: #363A77;
  border-radius: 0px;
}
.detail_leftbar_section .tabs_section .tabs_head_section button[aria-selected="true"] svg{
  color: #fff;
}
.detail_leftbar_section .tabs_section .tabs_head_section button svg{
  color: #000;
}
.detail_leftbar_section .tabs_section .basic_detail_section .head_text{
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #000;
}
.detail_leftbar_section .tabs_section .basic_detail_section p{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000;
}
.detail_leftbar_section .tabs_section .basic_detail_section .check_icon{
  color: #28C76F;
  font-size: 20px;
  margin-left: 5px;
}
.detail_leftbar_section .tabs_section .btn_primary{
  background-color: #363A77;
  padding: 12px;
  color: #fff;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
}
.detail_leftbar_section .link_section{
  border-bottom: 1px solid #DCDEDF;
  padding: 16px;
  display: block;
}
.detail_leftbar_section .link_section p{
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-weight: bold;
}
.chat_screen_bg .left_section{
  width: 75%;
  margin-right: 15px;
}
.chat_screen_bg .main_leftbar_section{
  width: 25%;
}
.ant-switch.ant-switch-checked{
  background-color: #2AB934;
}
.ant-switch.ant-switch-checked:hover{
  background-color: #2AB934 !important;
}

@media screen and (max-width: 1920px){
  .chat_screen_bg .left_section{
    width: 78%;
    margin-right: 15px;
  }
  .chat_screen_bg .main_leftbar_section{
    width: 22%;
  }
}

@media screen and (max-width: 1680px){
  .chat_screen_bg .left_section{
    width: 75%;
    margin-right: 15px;
  }
  .chat_screen_bg .main_leftbar_section{
    width: 25%;
  }
  .chat_screen_bg .left_section .chat_body_section .all_chats_section{
    width: 26%;
  }
  .chat_screen_bg .left_section .chat_body_section .chating_section{
    width: 74%;
  }
}

@media screen and (max-width: 1600px){
  .chat_screen_bg .left_section .chat_body_section .all_chats_section{
    width: 28%;
  }
  .chat_screen_bg .left_section .chat_body_section .chating_section{
    width: 72%;
  }
}

@media screen and (max-width: 1440px){
  .chat_screen_bg .left_section .chat_body_section .all_chats_section{
    width: 30%;
  }
  .chat_screen_bg .left_section .chat_body_section .chating_section{
    width: 70%;
  }
}
